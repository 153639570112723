/* Contenedor principal */
.react-bs-table-tool-bar {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 12px 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  /* Layout interno */
  .toolbar-inventario-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Elementos del toolbar */
  .toolbar-item {
    flex: 1 1 auto;
    min-width: 150px;
  }
  
  /* Barra de búsqueda */
  .toolbar-search {
    position: relative;
    flex-grow: 1;
    max-width: 400px;
  }
  
  .toolbar-search input {
    width: 100%;
    padding: 8px 35px 8px 15px;
    border: 1px solid #EFF3F6;
    border-radius: 4px;
    background-color: #FFFFFF !important;
    color: #515151 !important;
    transition: border-color 0.3s ease;
  }
  
  .toolbar-search input:focus {
    border-color: #000000;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
  
  .icon-search {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #B8B8B8;
  }
  
  /* Selects personalizados */
  .toolbar-select .select__control {
    border: 1px solid #EFF3F6;
    border-radius: 4px;
    min-height: 38px;
    background-color: #FFFFFF;
    color: #515151;
    box-shadow: none;
  }
  
  .toolbar-select .select__single-value {
    color: #515151;
  }
  
  .toolbar-select .select__indicator-separator {
    display: none;
  }
  
  .toolbar-select .select__dropdown-indicator {
    color: #515151;
  }
  
  .toolbar-select .select__menu {
    border: 1px solid #EFF3F6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  /* Botones de acción */
  .toolbar-actions {
    display: flex;
    gap: 8px;
    margin-left: auto;
  }
  
  .btn-toolbar-action {
    width: 38px;
    height: 38px;
    padding: 0;
    border: 1px solid #EFF3F6 !important;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #515151;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
  }
  
  .btn-toolbar-action:hover {
    background-color: #FEECE6;
    border-color: #B8B8B8 !important;
  }
  
  .btn-toolbar-action i {
    font-size: 16px;
  }
  
  /* Cajas de resumen */
  .toolbar-summary-box {
    flex: 1 1 calc(25% - 12px);
    min-width: 180px;
    background-color: #F5F5F5;
    border-radius: 4px;
    padding: 8px 15px;
    display: flex;
    flex-direction: column;
  }
  
  .summary-label {
    font-size: 13px;
    color: #6c757d;
    margin-bottom: 2px;
  }
  
  .summary-value {
    font-size: 16px;
    font-weight: 500;
    color: #212529;
  }
  
  /* Labels para los filtros */
  .toolbar-item label {
    font-size: 13px;
    color: #515151;
  }

  .totals {
    display: flex;
    flex-direction: row;
    gap:10px;
  }