.container-img-configuracion {
    background-color: #7F7F7F;
    position        : relative;
    width           : 100%;
    padding         : 25px;
    height          : 222px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    border-radius: 5px;

}

.container-img-configuracion .img-configuracion {
    max-width  : 100%;
    max-height : 100%;
    margin     : auto;
    position   : absolute;
    top        : 0;
    bottom     : 0;
    left       : 0;
    right      : 0;
    will-change: transform;
}

.container-options {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 5px 5px 5px;

}

.bnt-imagen-configuracion {
    padding         : 2px;
    z-index         : 1;
    outline         : 1px dashed #ccc;
    background-color: transparent;
    cursor          : pointer;
    width: 40px;
}

.bnt-imagen-configuracion img {
    width : 100%;
    cursor: pointer;
}

.container-configuration{
    display: flex;
    background-color: none;
    gap: 10px;
}
/* .container-configuration-menu{
    width: 25%;
    min-width: 200px;
} */

.container-configuration-menu p{
    margin: 0px;
}
/* .container-configuration-form{
    width: 75%;
} */
.container-configuration-menu-items{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.container-configuration-menu-item:hover{
    background-color: #EAEAE9;
    border-radius: 5px;
    transition: background-color 0.5s;
}

.container-configuration-menu-item{
    display: flex;
    align-items: center;
}
.container-configuration-menu-item p{
    margin: 0;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}

.title-container-configuration{
    display: flex ;
    justify-content: space-between;
    align-items: center;
  
}

.title-container-configuration p{
    margin: 0px;
    font-size: 22px;
}

/* .mb{
    background-color: #000000;
} */

.configuration-selected-item{
    background-color: #EAEAE9;
    border-radius: 5px;
}
.container-configuration-detail{
    background-color: #ffffff;
    border-radius: 15px;
    margin-top: 10px;

}

.save-button-configuration{
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 150px;
}

.save-button-configuration p{
    font-size: 15px;
    margin: 0px; 
}

.container-banner-configuration{
    width: 100%;
}


.container-banner-configuration hr{
    margin-bottom: 20px;
}
.container-logotipo-configuration{
    display: flex ;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.container-configuration-metodos-entrega{
    flex-direction: column;
    width: 100%;
}