@import '../../../../style/configs/color.css';
/* overwrites */

.nav .dd-menu{
    margin-top: 18px;
    color: white;
    cursor: pointer;
}
.nav .dd-menu em{
    margin-right: 12px;
    margin-left: 8px;
}
/*
    REACT DD MENU
*/
#bubble{
    width: 250px !important;
}
.grow-from-left-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

.grow-from-left-enter.grow-from-left-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
}


.grow-from-left-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}

.grow-from-left-leave.grow-from-left-leave-active {
  -webkit-transform: scale(0);
          transform: scale(0);
}


.grow-from-right-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

.grow-from-right-enter.grow-from-right-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
}


.grow-from-right-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}

.grow-from-right-leave.grow-from-right-leave-active {
  -webkit-transform: scale(0);
          transform: scale(0);
}


.grow-from-center-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

.grow-from-center-enter.grow-from-center-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
}


.grow-from-center-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}

.grow-from-center-leave.grow-from-center-leave-active {
  -webkit-transform: scale(0);
          transform: scale(0);
}


.grow-from-up-left-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

.grow-from-up-left-enter.grow-from-up-left-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
}


.grow-from-up-left-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}

.grow-from-up-left-leave.grow-from-up-left-leave-active {
  -webkit-transform: scale(0);
          transform: scale(0);
}


.grow-from-up-center-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

.grow-from-up-center-enter.grow-from-up-center-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
}


.grow-from-up-center-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}

.grow-from-up-center-leave.grow-from-up-center-leave-active {
  -webkit-transform: scale(0);
          transform: scale(0);
}


.grow-from-up-right-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

.grow-from-up-right-enter.grow-from-up-right-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
}


.grow-from-up-right-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}

.grow-from-up-right-leave.grow-from-up-right-leave-active {
  -webkit-transform: scale(0);
          transform: scale(0);
}


.dd-menu {
  display: inline-block;
  position: relative;
}

.dd-menu.dd-menu-center .dd-menu-items {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.dd-menu.dd-menu-left .dd-menu-items {
  left: 0;
}

.dd-menu.dd-menu-left .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
  right: 100%;
  padding-right: 0.5em;
}

.dd-menu.dd-menu-left .dd-menu-items .nested-reverse > span {
  left: 100%;
  padding-left: 0.5em;
}

.dd-menu.dd-menu-left.dd-menu-inverse .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
  right: 100%;
  padding-right: 0.3em;
}

.dd-menu.dd-menu-left.dd-menu-inverse .dd-menu-items .nested-reverse > span {
  left: 100%;
  padding-left: 0.3em;
}

.dd-menu.dd-menu-right .dd-menu-items {
  right: 0;
}

.dd-menu.dd-menu-right .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
  left: 100%;
  padding-left: 0.5em;
}

.dd-menu.dd-menu-right .dd-menu-items .nested-reverse > span {
  right: 100%;
  padding-right: 0.5em;
}

.dd-menu.dd-menu-right.dd-menu-inverse .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
  left: 100%;
  padding-left: 0.3em;
}

.dd-menu.dd-menu-right.dd-menu-inverse .dd-menu-items .nested-reverse > span {
  right: 100%;
  padding-right: 0.3em;
}

.dd-menu .dd-menu-items {
  position: absolute;
  z-index: 7;
  margin: 0.5em 0 0 0;
}

.dd-menu .dd-menu-items.dd-items-upwards {
  bottom: 100%;
  margin: 0 0 0.5em;
}

.dd-menu .dd-menu-items ul, .dd-menu .dd-menu-items ol {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  background-color: #fefefe;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.29);
}

.dd-menu .dd-menu-items ul.dd-items-right li > *, .dd-menu .dd-menu-items ol.dd-items-right li > * {
  text-align: right;
}

.dd-menu .dd-menu-items ul.dd-items-left li > *, .dd-menu .dd-menu-items ol.dd-items-left li > * {
  text-align: left;
}

.dd-menu .dd-menu-items ul.dd-items-center li > *, .dd-menu .dd-menu-items ol.dd-items-center li > * {
  text-align: center;
}

.dd-menu .dd-menu-items ul li:hover:not([role="separator"]):not(.separator),
.dd-menu .dd-menu-items ul li > *:focus, .dd-menu .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
.dd-menu .dd-menu-items ol li > *:focus {
  color: #3A7EFD !important;
  outline: none;
}

.dd-menu .dd-menu-items ul li > *:not(.dd-item-ignore), .dd-menu .dd-menu-items ol li > *:not(.dd-item-ignore) {
  display: block;
  width: auto;
  min-width: 100%;
  padding: 1em 1.5em;
  white-space: pre;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}

.dd-menu .dd-menu-items ul li > button, .dd-menu .dd-menu-items ol li > button {
  border: none;
  background: transparent;
}

.dd-menu.dd-menu-inverse .dd-menu-items ul, .dd-menu.dd-menu-inverse .dd-menu-items ol {
  color: #fff;
  background-color: #2c3e50;
}

.dd-menu.dd-menu-inverse .dd-menu-items ul li:hover:not([role="separator"]):not(.separator),
.dd-menu.dd-menu-inverse .dd-menu-items ul li > *:focus, .dd-menu.dd-menu-inverse .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
.dd-menu.dd-menu-inverse .dd-menu-items ol li > *:focus {
  color: #fff;
  background-color: #34495e;
}

.dd-menu.dd-menu-inverse [role="separator"], .dd-menu.dd-menu-inverse .separator {
  background-color: #45595e;
}

.dd-menu [role="separator"], .dd-menu .separator {
  content: '';
  display: block;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0.5em 0 0.5em 0;
}

.dd-menu .dd-items-upwards li.nested-dd-menu > span {
  bottom: 0;
  top: initial;
}

.dd-menu li.nested-dd-menu {
  position: relative;
}

.dd-menu li.nested-dd-menu > span {
  position: absolute;
  top: 0;
}
.dd-menu {
    input{
        padding: 0 0 0 1em !important;
        width: 176px;
        margin-bottom: 3px;
        min-width: 100%;
    }
}
.nav-regiones{
    margin-right: 15px !important;
    padding-left: 15px !important;
}
.nav-regiones .dd-menu{
    a{
        padding: 0 1.5em !important;
        /*font-size: 80% !important;*/
    }
}
.nav-regiones .dd-menu-items .dd-menu{
    margin-top: 0;
    cursor: pointer;
    width: 100%;
    a{
        padding: 0 0.1em 0 1.5em !important;
    }
}
.nav-regiones .dd-menu-items:hover{
    color: white !important;
    background-color: #A7A7A7;
}
.sucursal-nav-select{
    min-width: 250px;
    color: #07A879;
    font-size: 18px;
    border-left: solid 2px $negro-dark2;
    margin-left:18px;
    padding-left: 0.5rem !important;
    /* width: 70%; */
    .Select-control{
        cursor: pointer;
        background-color: transparent !important;
        border-radius: 0 !important;
        height: 27px;
        .Select-input {
            height: 1px;
        }
        /* margin-top: 10px; */
    }
    .Select-control .sucursal-select {
      background-color: $blanco;
      color: $negro-dark2 !important;
      border: 3px solid $negro-dark2;
    }
    .Select-control .Select-input:focus{
        background-color: transparent !important;
        outline-color: transparent !important;
    }
    .Select-clear-zone{
        display: none;
    }
    .Select-arrow-zone{
        display: none;
    }
}
.select-option-empresa {
    background-color: #E8E8E8;
    padding: 8px 10px  !important;
    font-weight: bolder;
    color: #515151 !important;
    border: 0 !important;
}

.select-option-sucursal {
    border-left: 2px solid transparent;
    padding: 7px 15px;
    color: $negro2;
    /* border-bottom: 1px solid #BABABA; */
}
.option-sucursal {
    padding: 0 0 0 5px;
    height: 23px;
}
.option-sucursal:hover {
    border-left: 2px solid #CECECE;
}
.option-sucursal-activo {
    border-left: 2px solid #515151 !important;
}
.select-option-sucursal:first-child {
    margin-bottom: 10px !important;
    border-bottom: 0px solid #BABABA;
}
.select-option-sucursal:last-child {
    border-bottom: 0px solid #BABABA;
}
.select-option-sucursal:hover {
    cursor: pointer;
}

.select-option-sucursal-2:hover {
    color: #66cc44;
}
.select-option-sucursal-3:hover {
    color: #ff5810;
}
.select-option-sucursal-4:hover {
    color: #007df9;
}
.select-option-sucursal-5:hover {
    color: #f30a76;
}
.select-option-sucursal-6:hover {
    color: #8300F7;
}
.select-option-sucursal-7:hover {
    color: #277793;
}
.select-option-sucursal-8:hover {
    color: #b00c25;
}
.select-option-sucursal-9:hover {
    color: #154d77;
}
.select-option-sucursal-10:hover {
    color: #69650f;
}
.select-option-sucursal-11:hover {
    color: #a8802f;
}

@media only screen and (max-width: 767px) {
    .sucursal-nav-select{
        min-width: 160px !important;
        margin-left: 0;
        padding-left: 0 !important;
        border-left: solid 0px $negro;
        width: 100%;
    }
    .nav-regiones{
        margin-right: 0 !important;
    }
    .container-empres-sucursal {
        width: 100%;
        border-left: solid 2px $negro;
    }
}
