@import "../../../../style/configs/color.css";

.breadcrumb-container {
    padding: 0;
}
.breadcrumb {
    background-color: #CDCDCD !important;
    font-size: 14px;
    color: #888888;
    padding: 0 0 0 25px !important;
    border-radius: 0 !important;
    position: relative;
    height: 23px;
    margin-bottom: 0 !important;
}
.breadcrumb:first-child {
    padding: 0 0 0 15px !important;
}
.flecha {
    right: -8.5px;
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: #CDCDCD;
    z-index: 1;
    border-width: 2px;
    border-style: solid;
    border-color: #FFF #FFF transparent transparent;
    transform: rotate(45deg);
}

.breadcrumb-activo {
    background-color: $negro-dark2;
    padding: 0 0 0 25px !important;
    font-size: 14px;
    color: #FFF;
    text-transform: capitalize;
    position: relative;
    height: 23px;
}
.flecha-activa {
    right: -8.5px;
    top: 3px;
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: $negro-dark2;
    z-index: 1;
    transform: rotate(45deg);
}

.crumb-item-activo {
    color: #FFF;
    margin-right: 15px;
}
.crumb-item {
    color: #888888;
    text-transform: capitalize;
    margin-right: 15px
}

.crumb-item:hover{
    color: $azul;
    text-decoration: solid !important;
}
.breadcrumb:hover {
    background-color: #C4C5C9 !important;
    .flecha {
        background-color: #C4C5C9;
    }
}

@media only screen and (max-width: 768px) {
    .breadcrumb{
        font-size: 16px;
    }
}
